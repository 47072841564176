@import '../../utils/vars';

.navbar {
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-flow: row nowrap;
    flex-wrap: wrap;
    height: auto;
    min-height: 80px;
    max-height: 108px;
    padding: 0 10px 18px 24px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    justify-content: space-between;
    align-items: flex-end;
    transition: all .4s ease-in-out;
    z-index: 15;

    @media screen and (max-width: $xl) {
        align-items: center;
    }

    @media screen and (min-width: $xxl) {
        .navMargin {
            margin: 0 20px;
        }
    }

    @media screen and (max-width: $xl) {
        padding-top: 20px;
        overflow: hidden;
    }

    @media screen and (max-width: $sm) {
        min-height: 76px;
        max-height: 76px;
        height: auto;
        padding: 11px 15px;
    }

    .navbarLinks {
        display: flex;
        flex-basis: auto;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        
        @media screen and (max-width: $xl) {
            display: block;
            width: 100%;
        }

        .pages {
            padding: $padding0;
            text-align: $textRight;
            text-transform: $textLowercase;
            width: 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          
            @media screen and (max-width: $xl) {
                width: 100%;
                flex-direction: column;
            }

            .navLink {
                letter-spacing: -0.75px;
                font-size: 14px;
                padding: $padding0;
                margin: 0;
                cursor: pointer;
                text-decoration: none;

                @media screen and (max-width: $xl) {
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
            }
        }

        .dropdownDivider {
            display: none;
            height: 0;
            margin: 0.5rem 0;
            overflow: hidden;
            border-top: 1px solid #e9ecef;
            
            @media screen and (max-width: $xl) {
                display: block;
            }
        }

        .externalSites {
            display: flex;
            text-align: $textRight;

            @media screen and (max-width: $xl) {
                flex-direction: column;
                margin-top: 25px;
            }

            span {
                display: block;
            }

            a {
                text-decoration: none;
                transition: all 500ms;
                color: $inverted-colour ;
            }

            .navMargin {
                align-self: center;
                flex-grow: 1;
                max-width: 100%;
                text-align: left;
                line-height: 12px;
                padding: 0 12px;
                transition: all 500ms;

                @media screen and (max-width: $xl) {
                    margin-top: 12px;
                    margin-bottom: 12px;
                    text-align: center;
                    padding-right: 0px;
                }

                &:hover {  
                        text-decoration: underline;
                        color: #F4B7DB; 
                }

                .main {
                    font-size: 18px;
                }

                .submain {
                    margin-top: 4px;
                    font-size: 12px;

                    @media screen and (max-width: $xl) {
                        margin-top: 6px;
                    }
                }

                .sub {
                    font-size: 10px;
                    padding-top: 1px;
                }
            }

            button {
                &:hover {
                    a {
                        color: black;
                    }
                }
            }

            .navButton {
                @media screen and (max-width: $xl) {
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .startLink {
                color: unset;
                padding-top: 2px;
                line-height: normal;
                width: 136px;
                height: 31px;
                font-weight: 400;
                align-items: center;
                display: flex;
                justify-content: center;
                margin-left: 12px;
                transition: all 0.3s;
                
                a {
                    color: $inverted-colour;
                    font-size: 16px;
                    text-decoration: none;
                }
            }
        }
    }

    .navbarBrand {
        margin-right: 44px;
        display: inline-block;
        padding-top: 0.3125rem;
        margin-bottom: -0.25rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;

        .logo {
            height: 48px;
            width: 147px;
            color: white !important;

            @media screen and (max-width: $sm) {
                width: 132px;
                height: 44px;
            }
        }
    }

    .navbarToggle {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 12px;
        padding-right: 12px;
        display: none;
        border: none;
        background: none;

        @media screen and (max-width: $xl) {
            display: block;
        }

        .navbarToggleIcon {
            width: 30px;
            height: 30px;
            display: inline-block;
            vertical-align: middle;
            content: "";
            background: no-repeat center center;
            background-size: 100% 100%;
        }
    }
}
