@import '../../utils/vars';

.baseCalculator {
    padding: 0px 12px;

    .labels {
        min-height: 72px;
        margin-left: -12px;
        
        @media screen and (max-width: $lg) {
            min-height: unset;
        }

        .heading {
            font-size: 24px;
            text-transform: uppercase;
            font-weight: normal;
            margin: 0;
        }

        .headingRight {
            text-align: right;
            margin: 0;
            margin-right: -12px;
            @media screen and (max-width: $lg) {
               text-align: left;
            }
        }

        .subHeading {
            font-size: 12px;
            margin: 0;
            @media screen and (max-width: $md) {
                margin-bottom: 0.5rem;
             }
        }
    }

    .tableHeaders {
        margin-bottom: 16px; 
        * {
            font-size: 16px;
        }

        .textRight {
            text-align: right;
            margin-bottom: 0px;
        }

        .textAlign {
            display: flex;
            align-items: flex-end;
        }

        .displayNone {
            display: flex;

            @media screen and (min-width: $lg) {         
                display: none;
            }
        }

        .flexLarge {
            padding-right: 0px;

            @media screen and (min-width: $lg) {         
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media screen and (max-width: $sm) {         
                padding-left: 0;
            }
        }

        .textHeader {
            width: 105px;
            text-align: center;
            margin-left: 20px;

            @media screen and (max-width: $xl) {
                padding-left: 12px;
                margin: 0 auto;
            }

            @media screen and (max-width: $lg) {
                width: auto;
            }
        }

        h5 {
            font-weight: bold;
            margin-bottom: 0px;
            margin: 0px;
        }

        .price {
            text-align: center;
        }

        .profit {
            text-align: right;
            margin-right: -12px;
        }
    }

    .productInfo {
        margin-bottom: 8px;
        margin-left: 0px;

        p {
            margin-top: 0px;
            margin-left: -12px;
        }

        .inputAlign {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        label {
            display: block;
            margin-bottom: 0px;

            input {
                text-align: center;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                padding: 0;
            }
        }

        .priceInput {
            text-align: center;
            margin: 0 auto;
            width: 105px;
            height: 24px;
            font-size: 14px;
            color: black;
            border-color: black;
            border-radius: 0;

            @media screen and (max-width: $lg) {
                width: 80px;
                height: 48px;
            }

       

            &:focus {
                background-color: #fff;
                outline: solid black;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
              }
        }

        .userProfit {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            p {
                text-align: right;
                margin-bottom: 0px;
            }

            @media screen and (min-width: $lg) {
                display: block;
            }
        }

        p {
            margin-bottom: 0px;
        }

        .textCenter {
            color: $calculator-grey;
            text-align: center;
            margin-left: -5px;
        }

        .textRight {
            text-align: right;
        }

        .productName {
            display: flex;
            align-items: center;

            p {
                margin-bottom: 0;
            }
            
            @media screen and (min-width: $lg) {
                display: none;
            }
        }

        &:last-child {
            margin-bottom: 11px;

            @media screen and (max-width: $lg) {
                margin-bottom: 30px;
            }
        }
    }

    .disclaimer {
        font-family: $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;
        color: $calculator-grey;
        font-size: 8px;
        line-height: 1.5;
        position: relative;
        width: 100%;
        margin: 0px;

        &:first-child {
            margin-top: 12px;
        }
    }
}
