@import '../../utils/vars';

.waitlist {
    margin-bottom: 60px;
    background-size: cover;
    background-position: center;
    margin-top: 40px;
    height: 304px;

    .formRow {
        &:first-child:not(.demoRow) {
            padding-top: 70px;
        }
    }

    .center {
        justify-content: center;
    }

    .demoRow {
        justify-content: center;
        height: 100%;
        align-items: center;
        display: flex;

        .demoCol {
            flex: 0 0 25%;
            max-width: 25%;
    
            @media screen and (max-width: $lg) {
                flex: 0 0 50%;
                max-width: 50%;
            }
    
            @media screen and (max-width: $md) {
                flex: 0 0 50%;
                max-width: 50%;
            }
    
            @media screen and (max-width: $sm) {
                flex: 0 0 75%;
                max-width: 75%;
            }  
        }
        
        .demoLink {
            height: 70px;
            width: 100%;

            a {
                color: $inverted-colour;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: 16px;

                &:hover {
                    color: $colour;
                }
            }
        }
    }

    .formRow {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-left: -5px;

        &:first-child:not(.demoRow) {
            padding-top: 70px;

            @media screen and (max-width: $md) {
                padding-top: 90px;
            }

            @media screen and (max-width: $sm) {
                padding-top: 70px;
            }
        }

        .buttonSize {
            width: 100%;
            height: 100%;
            padding-top: 13px;
            padding-bottom: 13px;
            font-size: 16px;
            line-height: 1.5;
            
            @media screen and (max-width: $md) {
                font-size: 14px;
            }
        }

        .inputRow {
            max-width: 50%;
            flex: 0 0 50%;
            margin-top: 40px !important;
            @media screen and (max-width: $lg) {
                margin-top: 20px !important;
                max-width: 75%;
                flex: 0 0 75%;
            }
        }

        .buttonRow {
            max-width: 25%;
            flex: 0 0 25%;
            margin-top: 40px !important;
            @media screen and (max-width: $lg) {
                margin-top: 20px !important;
                max-width: 75%;
                flex: 0 0 75%;
            }
        }

        .formElemCont, 
        .formElementButton {

            input {
                width: 100%;
                border-radius: 0;
                text-align: center;
                color: $colour;
                height: 50px;
                background-clip: padding-box;
                border: none;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                overflow: visible;
                margin: 0;
                font-size: 16px;
                padding: 0.375rem 0.75rem;
                
                &:focus {
                    box-shadow: 0 0 0.2rem $colour;
                    border-color: $colour;
                    outline: solid black;
                }
            }

            @media screen and (max-width: $lg) {
                &:last-child {
                    margin-top: 16px;
                }
            }
        }
    }
}
