@import '../../utils/vars';

.cookiePopup {
    text-align: center;
    position: fixed;
    width: 100%;
    background-color: $colour;
    color: $inverted-colour;
    font-family: $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;
    bottom: 0;
    font-size: 12px;
    z-index: 10;

    .txt {
        margin-top: 10px;
        margin-bottom: 10px;

        .cookieLink {
            color: $inverted-colour;

            &:hover {
                color: $inverted-colour;
            }
        }

        .closePopup {
            position: absolute;
            right: 55px;
            color: $inverted-colour;
            border: none;
            background-color: transparent;

            @media screen and (max-width: $lg) {
                position: static;
                margin-left: 16px;
            }
        }
    }
}
