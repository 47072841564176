@import '../../utils/vars';

.footerSection {
    flex: 0 0 15%;
    max-width: 15%;

    @media screen and (max-width: $lg) {
        flex: auto;
        max-width: 100%;
    }

    .accordion {
        display: none;

        @media screen and (max-width: $lg) {
            display: block;
        }

        h3 {
            color: white;
            font-size: 18px;
            text-align: left;
            font-weight: bold;
            margin-bottom: 0px;
        }

        a {
            font-size: 16px;
        }

        .heading {
            padding: 0;
        }

        button {
            align-items: flex-end;
            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 0px;
            background: none;
            border: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
          
            .arrow {
                margin-top: 1.5px;
                margin-right: 1.5px;
                transition: all ease-in-out 0.25s;
            }

            .expanded {
                transform: rotate(90deg);
            }
        }

        .collapse {
            max-height: 0;
            overflow: hidden;
            height: auto;
            transition: max-height ease-in-out 0.27s;
        }
    }

    @media screen and (max-width: $lg) {
        width: 100%;
    }

    .largeFooter {
        display: block;
        @media screen and (max-width: $lg) {
            display: none
        }
    }

    .heading {
        font-size: 18px;
        margin-bottom: 30px;
        font-weight: bold;
        color: $inverted-colour;
        width: calc(100% + 16px);

        @media screen and (max-width: $lg) {
            margin-bottom: 0px;
            padding: 14px 0;
        }
    }

    .links {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .linkItem {
            margin-bottom: 8px;

            .link {
                color: $inverted-colour;
                font-size: 16px;
                text-decoration: none;
                display: inline-block;
                width: calc(100% + 16px);

                &:hover {
                    text-decoration: underline;
                }

                &.combined {
                    .main {
                        letter-spacing: 1px;
                        display: block;
                    }

                    .sub {
                        font-size: 12px;
                        color: $footer-grey;
                        display: block;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
