@import '../../utils/vars';

.upsideCalculator {
    transition: max-height 0.500s;
    overflow: hidden;
    max-height: 53px;

	.accordionButton {
		width: 100%;
		height: 53px;
		background-color: #F5F5F5;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 0;
		border-radius: 0;
		margin-bottom: 1rem;
		padding-left: 0.5rem;
		padding-right: 1rem;
		
		@media screen and (max-width: $sm) {
			padding-right: 1.5rem;
		}

		h4 {
			display: inline-block;
			text-transform: uppercase;
			margin-bottom: 0px;
			font-size: 24px;
			font-weight: normal;
			margin: 0px;
		}

		img {
			transition: all ease-in-out 0.500s;
		}
	}

	.products {
		position: relative;

		.desc {
			margin-bottom: 1rem;
			font-size: 12px;
		}

		.productInfo {
			display: flex;
			align-items: flex-end;
			padding: 0 12px;

		.displayLrg {
			display: block;
			p {
				padding-left: 1rem;
			}

			@media screen and (max-width: $lg) {
				display: none;
			}
		}

		.displaySm {
			display: none;
			
			@media screen and (max-width: $lg) {
				display: block;
			}
		}

		p {
			margin: 0;
			margin-left: -12px;
		}

		.currency {
			padding-right: 0px;

			p {
				margin-bottom: 8px;
				text-align: right;
			}
		}

		.price {
				margin-bottom: 0px;
				text-align: left;

			@media screen and (max-width: $lg) {
				text-align: center;
				margin-right: -24px;
			}
		}
		
		.name {
				display: block;
				align-items: left;

			@media screen and (max-width: $lg) {
				display: flex;
			}

			p {
				margin-bottom: 0px;
			}
		}

		}

		.tableHeaders {
			padding: 0 12px;

			h5,
			h4 {
				margin: 0;
			}

			h4 {
				span {
					display: block;
				}

				&:last-child {
					text-align: right;
				}
			}
			
			* {
				font-size: 16px;
			}

			.padLeft {
				padding-left: 10px;
			}

			.displayLrg {
				h4 {
					text-align: left;
					padding-left: 1rem;
				}

				display: block;

				@media screen and (max-width: $lg) {
					display: none;
				}
			}

			.displaySm {
				display: none;

				@media screen and (max-width: $lg) {
					display: block;
				}
			}

			.textHeader {
				width: 105px;
				text-align: left;

				@media screen and (max-width: $lg) {
					width: auto;
					text-align: center;
					margin-right: -24px;
				}
			}
		}
	}
  
	.totalDivider {
		height: 1px;
		width: 81.94px;
		background-color: $colour;
		right: 0;
		bottom: 44px;
		float: right;
		position: absolute;
	}

	.total {
		font-size: 24px;
		margin-top: 23px;
		text-align: right;
		font-weight: bold;
		justify-content: flex-end;
		padding-right: 12px;
		margin-bottom: 1rem;
	}
}
