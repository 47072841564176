@import '../../utils/vars';

.userKnowledge {
    .info {
        margin-bottom: 40px;
        margin-top: 0px;
    }
    
    .center {
        justify-content: center;
    }

    .flexColCalc {
        flex: 0 0 50%;
        max-width: 50%;

        @media screen and (max-width: $xl) {
            flex: 0 0 75%;
            max-width: 75%;
        }
        
        @media screen and (max-width: $lg) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .flexColRight {
        @media screen and (min-width: $lg) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .videoPlayer {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%; 
        height: 0;

        iframe {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .questions {
        margin-top: 24px;
        text-align: center;

        span {
            display: block;
        }

        a {
            display: inline-block;
            margin: 0 auto;
        }
    }
}