@import '../../utils/vars';

.worksPageNav {
    margin-top: 162px;
    margin-bottom: 48px !important;

    @media screen and (max-width: $md) {
        margin-top: 132px;

    }

    .colWorksPage {
        flex: 0 0 25%;
        max-width: 25%;

        @media screen and (max-width: $lg) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media screen and (max-width: $md) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media screen and (max-width: $sm) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media screen and (min-width: $sm) {
        justify-content: center;
    }

    .buttonPadding {
        padding: 0 12px;
    }

    .navBtn {
        padding-left: 8%;
        padding-right: 8%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        line-height: 1.2;
    }
}

.seo {
    display: none;
}
