@import '../../utils/vars';

.infoBox {
    border: 1px solid $colour;
    margin-bottom: 40px;
    padding-right: 22px;
    position: relative;

    .num {
        font-family: $number-font, $font, $fallback-font;
        font-size: 36px;
        left: percentage(24 / 616);
        display: inline-block;
        position: absolute;
        margin: 24px 0;
        font-weight: bold;

        @media screen and (max-width: $sm) {
            font-size: 24px;
            margin: 16px 0;
        }

        @media screen and (max-width: $iphone5w) {
            font-size: 18px;
        }
    }

    .title {
        font-size: 24px;
        margin-bottom: 16px;
        padding-top: 0.5rem;
        display: block;
        margin-left: auto;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.2;

        @media screen and (max-width: $sm) {
            font-size: 16px;
        }
    }

    .description {
        font-family: $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;
        margin-bottom: 24px;
        margin-left: auto;

        @media screen and (max-width: $sm) {
            font-size: 12px;
        }
    }

    .title,
    .description {
        // margin-left: 90px;
        width: percentage(511 / 616);
    }

    .nextArrow {
        left: 0;
        right: 0;
        bottom: -19px;
        position: absolute;
        margin: 0 auto;
    }
}
