@import '../../utils/vars';

.footer {
    background-color: $txt-colour;
    color: $inverted-colour;
    padding-top: 24px;
    padding-bottom: 16px;
    font-family: $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;

    .footerCol {
        flex: 0 0 30%;
        max-width: 30%;
    }


    @media screen and (max-width: $lg) {
        padding-top: 0px;
    }

    a {
        color: $inverted-colour;
        text-decoration: none;
        &:hover {
            color: $inverted-colour !important;
            text-decoration: none;
        }
    }

    .bottom, .bottomMobile {
        .row {
            &:first-child {
                margin-bottom: 8px;
            }
        }
        
        .bottomLinks {
            list-style-type: none;
            font-size: 11px;

            :nth-child(2) {
                margin-left: 24px;
                margin-right: 24px;
            }

            a {
                color: $inverted-colour;
            }
        }
        display: block;

        @media screen and (max-width: $lg) {
            display: none;
        } 
        
        span {
            display: inline-block;
        }
    }

    .bottomMobile {
        display: none;

        .bottomLinks {
            list-style-type: none;
            font-size: 11px;
            display: flex;
            justify-content: center;

            .textLeft {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
                text-align: left;
                margin: 0;
                padding: 0 12px;
                text-decoration: underline;
                margin-bottom: 8px;
            }

            .textRight {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
                text-align: right;
                margin: 0;
                padding: 0 12px;
                text-decoration: underline;
                margin-bottom: 8px;
            }
        }
        
        @media screen and (max-width: $lg) {
            display: block;
        }
    }

    .top {
        margin-bottom: 64px;
        padding-right: 12px;
        padding-left: 12px;

        .socialContainer {
            flex: 0 0 6%;
            max-width: 6%;
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: $lg) {
                flex: auto;
                max-width: 100%;
            }

            @media screen and (max-width: $lg) {
                justify-content: center;
                width: 100%;
                padding: 0;
            }

            .socialMedia {
                display: flex;

                a {
                    display: block;
                }
            }
        }

        .socialMedia {
            height: 25px;

            a:first-child {
                margin-right: 15.67px;
                right: 0;
            }
        }
    }
}