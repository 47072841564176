@import '../../utils/vars';

.errorMsgs {
    margin-top: 55px;
    margin-bottom: 55px;
    text-align: center;
    padding-top: 106px;

    @media only screen and (max-width: $sm) {
        padding-top: 4rem;
    }


    .title,
    .errorMsg {
        font-size: 18px;
        text-align: center;
        font-weight: normal;
        text-transform: uppercase;
        margin: 0 0 0.5rem 0;
    }

    .start {
        font-size: 24px;
        text-transform: uppercase;
        text-align: center;
        margin: 0 0 0.5rem 0;
    }

    .errorCode, .errorCodeUndefined {
        font-size: 101px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .errorCodeUndefined {
        font-size: 91px;
        @media only screen and (max-width: $sm) {
            font-size: 3rem;
        }
    }

    img {
        display: block;
        margin: 0 auto;
    }

    .contact {
        margin-top: 29px;
        text-align: center;
        margin-bottom: 0px;

        a {
            &:hover {
                color: $hovered-btn;
            }
        }
    }
}
